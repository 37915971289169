.resetPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
  div {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 1.9rem;
    line-height: 4.6rem;
    color: #2c3c55;
    &.changed{
      text-align: center;
      margin-top: 32px;
      line-height: 150%;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    margin-top: 48px;
    label {
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-family: "Inter", sans-serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;

      color: #4c8bf5;
      section {
        position: relative;
      }
      input {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 21px;
        padding: 12px 24px;
        color: #2c3c55;
        border: 1px solid rgba(118, 120, 128, 0.5);
        border-radius: 4px;
        outline: none;
        width: 39rem;
        &::placeholder {
          color: #4f4f4f;
        }
      }
      img {
        position: absolute;
        top: 50%;
        right: 18.25px;
        cursor: pointer;
        width: 28px;
        height: 28px;
        transform: translateY(-50%);
      }
    }
    button {
      outline: none;
      border: none;
      outline: none;
      color: white;
      font-family: "Inter", sans-serif;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: white;
      padding: 11px 0;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      background: #2c3c55;
      border: 2px solid transparent;
      &:hover {
        border-color: #2c3c55;
        background: transparent;
        color: #2c3c55;
      }
    }
    p {
      font-size: 1.5rem;
      font-weight: 400;
      color: rgb(243, 83, 83);
      margin-top: -2rem;
      margin-bottom: -2rem;
      text-align: center;
      width: 39rem;
      &.active {
        color: #198754;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .resetPassword {
    form {
      width: 80% !important;

      input {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .resetPassword {
    form {
      label input {
        font-size: 1.2rem !important;
      }
      button {
        font-size: 1.2rem !important;
      }
    }
  }
}
