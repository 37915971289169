.create-new-shift-modal-1-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  background: rgba(44, 60, 85, 0.795);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-new-shift-modal-1 {
  padding: 16px;
  background: #ffffff;
  display: inline-block;

  position: relative;

  box-shadow: 2px 16px 40px 12px rgba(44, 49, 49, 0.16);
  border-radius: 8px;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
  }
  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;

    margin-top: 8px;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 16px;
    margin-top: 24px;
    margin-bottom: 20px;
  }
  .input-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #a7b6d1;
    border-radius: 5px;
    padding: 16px 20px;
    input {
      margin: none;
      outline: none;
      border: none;
    }
    select {
      margin: none;
      outline: none;
      border: none;
    }
    svg {
      padding-right: 11px;

      width: 26px;
      height: 20px;
    }
  }
  #isolation-patients {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
  .submit-wrapper {
    display: flex;
    .btn-next {
      padding: 12px 52px;
      background: #4c8bf5;
      border: none;
      outline: none;
      border-radius: 4px;
      color: #ffffff;
      margin-left: auto;
    }
  }
  .close-btn {
    background: #e5f3ff;
    opacity: 0.8;
    border-radius: 3px;
    display: inline-block;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 10.63px;
      height: 10.63px;
    }
  }
}
