.employee_details {
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      color: #2c3c55;
      margin-left: 12px;
    }
  }

  .profile {
    height: 141px;
    width: 100%;
    background-color: white;
    padding: 24px;
    display: flex;
    align-items: center;
    img {
      height: 93px;
      width: 93px;
      margin-right: 16px;
      border-radius: 4px;
    }
    .profile_detail {
      font-family: "Inter";
      font-style: normal;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-right: auto;
      div {
        font-weight: 500;
        font-size: 24px;
        line-height: 140%;
        color: #2c3c55;
      }
      span {
        width: 446px;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #a7b6d1;
      }
      &_buttons {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        button {
          font-weight: 500;
          font-size: 16px;
        }
        &_timesheet {
          padding: 17px 40px;
          height: 48px;
          background: #4c8bf5;
          border-radius: 4px;
          color: #ffffff;
          border: none;
        }
        .approve-decline-btn {
          font-weight: 500;
          font-size: 16px;
        }
        .approve {
          background: #53d258;
        }
        .decline {
          background: #ff6456;
        }
        &_download {
          padding: 17px 40px;
          height: 48px;
          background: white;
          border-radius: 4px;
          color: #4c8bf5;
          border: 1px solid #4c8bf5;
        }
      }
    }
  }

  .employee_details_section {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #4b6182;
    padding: 20px 0px;
  }
}
