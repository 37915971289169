.reviews-info {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(138, 138, 138, 0.08);
  border-radius: 4px;

  .reviews-content-gap {
    gap: 24px;
  }
}
