.profile_homepage {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 32px 24px;
  &_dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 258px;
    background: #ffffff;
    box-shadow: 0px 0px 25px rgba(138, 138, 138, 0.08);
    border-radius: 4px;
    .uploader {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      position: relative;
      width: 128.5px;
      height: 128.5px;
      background: #ffffff;
      // border: 1px solid #c5d4eb;
      box-shadow: 0px 0px 25px rgba(138, 138, 138, 0.08);
      border-radius: 13px;
      .image {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 27px;
        height: 27px;
        background: #74b8ff;
        box-shadow: 0px 0px 25px rgba(138, 138, 138, 0.08);
        border-radius: 50%;
        top: 90%;
        left: 90%;
      }
    }
    .uploader_name {
      font-weight: 500;
      font-size: 24px;
      line-height: 140%;
      color: #2c3c55;
    }
    .uploader_email {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #7183a5;
    }
  }
  &_header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #2c3c55;
  }
}
