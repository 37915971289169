.agency_setting_details {
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      color: #2c3c55;
      margin-left: 12px;
    }
  }
  .agency {
    height: 141px;
    width: 100%;
    background-color: white;
    padding: 24px;
    display: flex;
    align-items: center;
    img {
      height: 93px;
      width: 93px;
      margin-right: 16px;
    }
    .agency_detail {
      font-family: "Inter";
      font-style: normal;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-right: auto;
      div {
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: #2c3c55;
      }
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #4b6182;
      }
      &_button {
        padding: 10px 40px;
        background: #4c8bf5;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          color: #ffffff;
        }
      }
    }
  }
}
