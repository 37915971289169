.profile {
  width: 316px;
  background-color: white;
  box-shadow: 0px 0px 25px rgba(138, 138, 138, 0.08);
  border-radius: 4px;
  &_seperation {
    border-bottom: 1px solid #e5f3ff;
  }
  &_closeIcon {
    margin: 31px 24px 0px 0px;
    float: right;
  }
  &_content {
    &_container {
      padding: 20px 0px 20px 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &_label {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: #2c3c55;
        margin: 8px 0px 0px 0px;
      }
    }
  }

  &_info {
    display: flex;
    flex-direction: column;
    margin: 20px 24px;
    gap: 16px;
    div {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #2c3c55;
    }
    .email_phone {
      display: flex;
      align-items: center;
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #4b6182;
        margin-left: 8px;
      }
    }
  }

  &_review {
    margin: 20px 24px;
    &_header {
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      &_view {
        color: #4c4ddc;
      }
      &_review {
        color: #2c3c55;
      }
    }
    &_buttons {
      display: flex;
      flex-direction: column;
      padding: 20px 0px;
      gap: 8px;
      &_invite {
        padding: 14px 28px;
        width: 100%;
        height: 40px;
        background: #4c8bf5;
        border-radius: 4px;
        border: none;
        span {
          font-weight: 500;
          font-size: 14px;
          color: #ffffff;
          text-align: center;
        }
      }
      &_details {
        padding: 14px 28px;
        width: 100%;
        height: 40px;
        background: white;
        opacity: 0.8;
        border: 2px solid #4c8bf5;
        border-radius: 4px;
        span {
          font-weight: 500;
          font-size: 14px;
          color: #4c8bf5;
          text-align: center;
        }
      }
    }

    &_stars {
      display: flex;
      align-items: center;
      margin: 15px 0px;
      &_content {
        display: flex;
        flex-direction: column;
        margin-left: 8px;
        div {
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          color: #2c3c55;
        }
        .Rating {
          font-size: 14px;
        }
      }
    }
    &_comments {
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      color: #7183a5;
    }
  }
}
