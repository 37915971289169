.agency-count {
  padding: 20px 16px;
  width: 100%;
  height: 153px;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(138, 138, 138, 0.08);
  border-radius: 4px;
  .agency-header-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    margin-bottom: 12px;
    .total-agencies-label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #7183a5;
    }
  }
  .agency-count-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .count {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 44px;
      line-height: 120%;
      color: #2c3c55;
    }
    .view-all-btn {
      padding: 14px 28px;
      opacity: 0.8;
      border: 2px solid #4c8bf5;
      border-radius: 4px;
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #4c8bf5;
      }
    }
  }
}
