.super-admin-container {
  display: flex;

  .super-admin {
    flex: 1;
    .dashboard-header {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      color: #2c3c55;
      margin-bottom: 16px;
    }
    .recent-agencies-header {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      color: #2c3c55;
      margin: 24px 0px 16px 0px;
    }
  }

  .line-chart {
    background-color: white;
    padding: 40px 60px 60px 60px;
    width: 100%;
    height: 100%;
  }

  .request-card-container {
    width: 100%;
    display: flex;
    gap: 16px;
  }

  .side-notifications {
    padding-left: 16px;
    .alerts-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 211px;
      .alerts {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 140%;
        color: #2c3c55;
      }
      .view-notifications {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: #4c4ddc;
      }
    }

    .notification-card {
      display: flex;
      align-items: center;
      padding: 24px 16px;
      gap: 10px;
      background: #ffffff;
      box-shadow: 0px 0px 25px rgba(138, 138, 138, 0.08);
      border-radius: 4px;
      margin: 16px 0px;
      width: 415px;

      .notification-content-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        .notication-content {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          color: #2c3c55;
        }
        .notification-time {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 160%;
          color: #a7b6d1;
          text-align: right;
        }
      }
    }
  }
}

@media screen and (min-width: 10px) {
  .agency-dashboard.super-admin-container {
    // width: fit-content;
    width: (max(calc(100vw - 300px)), fit-content);
  }
  .side-notifications {
    flex-shrink: 1;
  }
}

@media screen and (max-width: 1060px) {
  .agency-dashboard.super-admin-container {
    flex-direction: column;
    gap: 16px;
    .side-notifications .notification-card {
      width: 100%;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  /* styles for tablets */
}
