.document_details {
  display: flex;
  align-items: center;
  padding: 9px 14px;
  width: 348px;
  height: 64px;
  border: 1px solid #c5d4eb;
  border-radius: 4px;
  justify-content: space-between;
  .resume-info {
    display: flex;
    align-items: center;
    gap: 8px;
    label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      color: #4b6182;
    }
  }
  .eye_ball-border {
    width: 46px;
    height: 0px;
    border: 1px solid #c5d4eb;
    transform: rotate(90deg);
  }
  .present-document {
    path {
      fill: rgb(76, 139, 245, 0.95);
    }
  }
}
