.splash-carousel {
  .carousel-button {
    width: 6px;
    height: 6px;

    /* Secondary/Purple100 */

    background: #cacaf6;
    border-radius: 15px;
    transition: all 0.3s ease-in-out;
  }
  .active-carousel-button {
    width: 16px;
    height: 6px;

    /* Secondary/Green600 */

    background: #4c4ddc;
    border-radius: 23px;
  }
  .carousel-dots {
    display: flex;
    gap: 4px;
    button {
      cursor: pointer;
      border: none;
    }
  }
  .carousel-text-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
    /* or 38px */

    text-align: center;

    /* Base/Black100 */

    color: #2c3c55;
  }

  .carousel-text-body {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    text-align: center;

    /* Base/Black60 */

    color: #7183a5;
  }
  .carousel-dots {
    display: flex;
    justify-content: center;
  }
}
