.pagination_wrapper {
  width: auto;
  margin: 20px auto 0;
}

.shifts_card_wrapper {
  gap: 15px;
  display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(372px, 440px));
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
}

.no_shifts_wrapper{
  grid-column: 2;
  display: flex;
  justify-content: center;
  margin-top: 12.5%;
}