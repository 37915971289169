.appBar {
  &_container {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    padding: 12px 24px;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #e5f3ff;
    align-items: center;
    gap: 22px;
    &_content {
      background-color: #e5f3ff;
      width: 459px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 12px;
      gap: 16px;
      border-radius: 4px;
    }
    input {
      border: none;
      outline: none;
      flex: 1;
      background-color: transparent;
      width: 100%;
      font-family: "Inter", sans-serif;
      height: 40px;
    }
  }
  &_profile {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    .appBar_profile-notification-icon {
      margin-right: 8px;
      cursor: pointer;
      &.active {
        rect {
          fill: #4c4ddc;
        }
        path{
          stroke: white;
        }
      }
    }
    &-info {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #2c3c55;
      pointer-events: none;
      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 160%;
        color: #4b6182;
      }
    }
    section {
      position: relative;
      img {
        width: 48px;
        height: 48px;
      }
      span {
        width: 18px;
        height: 18px;
        position: absolute;
        top: -1%;
        right: -1%;
        z-index: 10;
        background-color: #53d258;
        border-radius: 50%;
        border: 3px solid white;
      }
    }
  }
}
