.super-admin-container {
  display: flex;
  .super-admin {
    flex: 1;
    .dashboard-header {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      color: #2c3c55;
      margin-bottom: 16px;
    }
    .recent-agencies-header {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      color: #2c3c55;
      margin: 24px 0px 16px 0px;
    }
  }

  .agency_list_table_container {
    width: 100%;
    // height: 560px;

    border-radius: 8px 8px 0px 0px;
    .details_table_cell {
      border: 1px solid #c5d4eb;
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #2c3c55;
      }
      .field-text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: #4b6182;
      }
    }
  }

  .side-notifications {
    padding-left: 16px;
    .alerts-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 211px;
      .alerts {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 140%;
        color: #2c3c55;
      }
      .view-notifications {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: #4c4ddc;
      }
    }

    .notification-card {
      display: flex;
      align-items: center;
      padding: 24px 16px;
      gap: 10px;
      background: #ffffff;
      box-shadow: 0px 0px 25px rgba(138, 138, 138, 0.08);
      border-radius: 4px;
      margin: 16px 0px;
      width: 415px;

      .notification-content-container {
        display: flex;
        flex-direction: column;
        .notication-content {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          color: #2c3c55;
        }
        .notification-time {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 160%;
          color: #a7b6d1;
          text-align: right;
        }
      }
    }
  }
}
