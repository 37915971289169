.notification_page {
  width: 415px;
  height: 553px;
  padding: 24px;
  overflow-x: auto;
  background: #ffffff;
  border: 1px solid #e5f3ff;
  box-shadow: 2px 16px 40px 12px rgba(44, 49, 49, 0.16);
  border-radius: 4px;
  position: absolute;
  z-index: 1000;
  position: absolute;
  top: 36px;
  right: 6px; 

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      color: #2c3c55;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 160%;
      text-align: right;
      color: #4c4ddc;
      cursor: pointer;
    }
  }
}
