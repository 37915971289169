.notifiation_home_page {
  padding: 25px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  &_header {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      color: #2c3c55;
    }
  }
  .notification_card_container {
    background: #ffffff;
    box-shadow: 0px 0px 25px rgba(138, 138, 138, 0.08);
    height: 101px;
    width: 100%;
    padding: 24px 16px;
    border-radius: 4px;
    .notification_card {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
      &_content {
        display: flex;
        flex-direction: column;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #2c3c55;
      }
    }
    .time {
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      text-align: right;
      color: #a7b6d1;
    }
  }
}
