.reference_details {
  width: 538px;
  border: 1px solid #c5d4eb;
  padding: 16px;
  border-radius: 4px;
  .reference_header {
    display: flex;
    margin-bottom: 16px;
    gap: 12px;
    label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #2c3c55;
    }
  }
  .ref_info {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    .reference_content {
      margin: 16px 0px;
      gap: 12px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #7183a5;
    }

    .reference_content_data {
      margin: 16px 0px;
      gap: 12px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #2c3c55;
    }
  }
}
