.notification {
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid #e5f3ff;
  filter: drop-shadow(0px 0px 25px rgba(138, 138, 138, 0.08));
  padding: 24px 0px 12px 0px;
  cursor: pointer;
  &_container {
    min-height: 48px;
    min-width: 48px;
    background-color: #e5f3ff;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 8px;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #2c3c55;
    span {
      display: flex;
      justify-content: flex-end;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      color: #a7b6d1;
    }
  }
}
