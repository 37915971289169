.review_details {
  .review_stars {
    display: flex;
    align-items: center;
    margin: 15px 0px;
    .review_content {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      div {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #2c3c55;
      }
      .Rating {
        font-size: 14px;
      }
    }
  }
  .review_comments {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #7183a5;
  }
}
