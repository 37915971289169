.timesheetModal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: rgb(0, 0, 0, 0.5);
    z-index: 5000;
    &__box {
        display: flex;
        flex-direction: column;
        padding: 24px;
        background-color: white;
        border-radius: 4px;
    }
}
