.homepage {
  display: flex;
  min-height: 100vh;
  max-width: 100vw;
  width: 100vw;
  max-height: 100vh;

  //
  overflow: hidden;

  &_container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: calc(100% - 240px);
  }
  &_content {
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 64px;
    overflow-y: auto;
    &-header {
      a {
        text-decoration: none;
      }
    }
    section {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .homepage_content-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        color: #7183a5;
        span {
          font-weight: 700;
          font-size: 12px;
          line-height: 160%;
          color: #4c4ddc;
        }
      }
      .homepage_content-box {
        // display: flex;
        // align-items: center;
        // gap: 16px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(178px, 1fr));
        gap: 16px;
      }
    }
  }
}
