.add-facility-modal-wrapper {
  display: flex;
  justify-content: center;
  background: rgb(44, 60, 85, 0.9);
  width: 100%;
  height: 100vh;
  // align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;

  overflow-y: auto;

  padding-block: 30px;

  .add-facility-modal {
    padding: 16px 24px;
    margin-inline: 16px;

    width: 744px;
    // height: 621px;

    /* Shadow/Elevation 03 */
    background: #ffffff;

    box-shadow: 2px 16px 40px 12px rgba(44, 49, 49, 0.16);
    border-radius: 8px;
    height: fit-content;
    header {
      display: flex;
      justify-content: space-between;
    }
    input {
      width: 100%;
    }
    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      margin-bottom: 8px;
    }
    .description {
      font-size: 12px;
      line-height: 160%;

      display: flex;
      align-items: center;

      color: #7183a5;

      margin-bottom: 24px;
    }
    label {
      border: 1px solid #a7b6d1;
      border-radius: 4px;
      padding: 16px 20px;
      display: inline-block;
      display: flex;
      gap: 10px;
      margin-bottom: 16px;

      display: flex;
      align-items: center;
    }
    .full-name-input {
      width: 100%;
    }
    input {
      border: none;
      outline: none;
    }
    .two-inputs {
      display: flex;
      gap: 8px;
      label {
        flex: 1;
      }
    }
    .additional-details {
      height: 132px;
      textarea {
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        font-family: "Inter";
        font-style: normal;

        color: #7183a5;

        position: relative;
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        &::placeholder {
          position: absolute;
          top: 0;
        }
      }
    }
    .upload-image-text {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;

      color: #4b6182;

      margin-bottom: 8px;
    }
    .upload-image {
      margin: 0;
      flex-direction: column;
      .svg-wrapper {
        margin-top: 31px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .pseudo-btn {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #7183a5;

        // margin-bottom: 29px;
      }
    }
    .add-facility-btn {
      padding: 12px 54px;
      background: #4c8bf5;
      margin-top: 24px;
      border: none;
      outline: none;
      border-radius: 4px;
      position: relative;
      left: 100%;
      transform: translateX(-100%);

      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #ffffff;
    }
  }
  .selected-images {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .selected-image-div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
  }
}
