.time_sheet_details {
  padding: 32px 24px 28px 24px;
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      color: #2c3c55;
      margin-left: 12px;
    }
  }

  .profile {
    height: 141px;
    width: 100%;
    background-color: white;
    padding: 24px;
    display: flex;
    align-items: center;
    img {
      height: 93px;
      width: 93px;
      margin-right: 16px;
    }
    .profile_detail {
      font-family: "Inter";
      font-style: normal;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-right: auto;
      div {
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: #2c3c55;
      }
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #4b6182;
      }
      &_time_sheet_button {
        display: flex;
        justify-content: flex-end;
        padding: 17px 40px;
        height: 48px;
        background: white;
        border-radius: 4px;
        color: #4c8bf5;
        border: 2px solid #4c8bf5;
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        align-items: center;
      }
    }
  }
  .details_date_container {
    display: flex;
    align-items: center;
    padding: 24px 0px;
    justify-content: space-between;
    .details_header {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #4b6182;
      font-family: "Inter";
      font-style: normal;
    }

    .date_container {
      display: flex;
      gap: 12px;
      align-items: center;
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 150%;
        color: #7183a5;
      }
    }
  }

  .table_container {
    border-radius: 8px 8px 0px 0px;
    .table-head {
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #2c3c55;
      }
      .details_table_cell {
        border: 1px solid #c5d4eb;
        width: 20%;
      }
    }

    .table-row {
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: #4b6182;
      }
      .details_table_cell {
        border: 1px solid #c5d4eb;
        width: 20%;
      }
    }
  }
}

.margin-center {
  // width: auto;
}
.margin-center .loader::after {
  margin: auto;
}
.margin-center .loader {
  width: 100%;
  margin-top: 10px;
}
