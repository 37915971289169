.facility-profile-container {
  padding: 32px 24px;

  overflow-y: auto;

  header {
    display: flex;
    gap: 20.4px;
    align-items: center;
    margin-bottom: 16px;
    span {
      /* Desktop/Body/Bold/L */

      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;

      color: #2c3c55;
    }
  }
  .images-section {
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 0px 25px rgba(138, 138, 138, 0.08);
    border-radius: 4px;
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 24px;
    // width: max-content;
    .main-img {
      // flex-basis: 937px;
      height: auto;
      // max-height: 516px;
      height: 516px;
      width: 100%;
      max-width: 100%;
      border-radius: 4px;
      object-position: center;
      object-fit: cover;
    }
    .secondary-imgs {
      flex-basis: 149px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex: 0 0;
      object-position: center;
      flex-basis: 149px;
      .secondary-img {
        width: 100%;
        height: auto;
        border-radius: 4px;
      }
    }
    margin-bottom: 24px;
  }
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 16px;
    color: #4b6182;
  }
  .facility-detail-section {
    padding: 24px;
    /* Shadow/Elevation 01 */
    background: #ffffff;

    box-shadow: 0px 0px 25px rgba(138, 138, 138, 0.08);
    border-radius: 4px;

    .top-bar {
      display: flex;
      justify-content: space-between;
      .facility-detail {
        display: flex;
        gap: 16px;
        align-items: center;
        .icon-wrapper {
          background: rgba(154, 205, 255, 0.2);
          border-radius: 4px;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .title {
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          color: #7183a5;
          margin-bottom: 8px;
        }
        .details {
          font-size: 16px;
          line-height: 150%;
          display: flex;
          align-items: center;
          color: #2c3c55;
          &:first-of-type {
            text-transform: capitalize;
          }
        }
      }
      margin-bottom: 24px;
    }
    .bold-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;

      display: flex;
      align-items: center;

      color: #2c3c55;
      margin-bottom: 8px;
    }
    .description-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;

      display: flex;
      align-items: center;

      color: #7183a5;
    }
  }
}
