.give-employee-review-modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(44, 60, 85, 0.795);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .review-rows2 {
    width: 100%;
  }

  .text-area {
    outline: none;
    border: none;
    background-color: #f4f4f4;
    resize: none;
    padding: 12px 16px;
    width: 100% !important;
  }
  .give-employee-review-modal {
    padding: 16px;
    background: #ffffff;
    display: inline-block;
    position: relative;
    box-shadow: 2px 16px 40px 12px rgba(44, 49, 49, 0.16);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 400px;
    align-items: center;
    justify-content: center;
    //   gap:40px
  }
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
    gap: 28px;
    .close-btn {
      cursor: pointer;
      background: #e5f3ff;
      opacity: 0.8;
      border-radius: 3px;
      display: inline-block;
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .btn {
    padding: 12px 52px;
    background: #4c8bf5;
    border: none;
    outline: none;
    border-radius: 4px;
    cursor: pointer;
    color: #ffffff;
    // margin-left: auto;
    margin-top: 20px;
  }
  .review-rows {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .review-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 12px;
  }
  .text {
    font-size: 16px;
    outline: none;
    font-weight: 500;
  }
}
