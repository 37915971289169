.emailModal {
    padding: 24px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    gap: 42px;
    h2 {
        font-size: 32px;
        font-weight: 600;
    }
    form {
        display: flex;
        flex-direction: column;
        gap: 12px;
        label {
            display: flex;
            flex-direction: column;
            gap: 8px;
            span {
                font-size: 17px;
                font-weight: 500;
            }
            input {
                outline: none;
                border: none;
                background-color: transparent;
                width: 650px;
                padding: 16px;
                border-radius: 6px;
                border: 1px solid #eee;
                font-size: 16px;
                font-weight: 400;
            }
        }
        button {
            display: flex;
            flex-direction: column;
            align-items: center;
            outline: none;
            border: none;
            background-color: transparent;
            background-color: #4c8bf5;
            padding: 16px 36px;
            color: white;
            width: fit-content;
            border-radius: 8px;
            font-size: 16px;
            margin-top: 8px;
            cursor: pointer;
            transition: all 0.2s ease-in;
            border: 1px solid #4c8bf5;
            &:hover {
                color: #4c8bf5;
                background-color: transparent;
            }
        }
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9999;
        background-color: #00000030;
        width: 100vw;
        min-height: 100vh;
    }
}
