.sideBar {
  &_container {
    width: 240px;
    height: auto;
    background-color: #4c8bf5;
    height: 100vh;
    display: flex;
    flex-direction: column;
    // padding-bottom: 24px;
    &.active-menu {
      width: 50px;
      .sideBar_container_logo {
        padding: 16px 12px;
        svg:first-of-type {
          display: none;
        }
      }
      .sideBar_content {
        display: none;
      }
    }

    &_logo {
      display: flex;
      justify-content: space-between;
      padding: 19px 27px 24px 27px;
      svg:last-of-type {
        cursor: pointer;
      }
    }
  }
  &_content {
    flex: 1;
    display: flex;
    flex-direction: column;

    div {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &.active {
        background-color: #5ca8ff;
        border-left: 4px solid#4c4ddc;
        transition: all 0.3s ease-in-out;
      }
      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #ffffff;
        margin-left: 11px;
      }
      &.border {
        border-top: 1px solid #5ca8ff;
        padding: 16px 16px;
        margin: 0 8px;
      }
      &:last-of-type {
        margin-top: auto;
      }
    }
  }
  .sidebar-link {
    display: flex;
    text-decoration: none;
    div {
      width: 100%;
    }
  }
}
