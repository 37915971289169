.root {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-top: 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background: rgb(43, 59, 84, 0.8);
  width: 100vw;
  height: 100vh;
  z-index: 999;

  .create-agency {
    width: 728px;
    // height: 422px;
    // height: 503px;

    background: #ffffff;
    border-radius: 4px;
    padding: 16px 24px;

    position: relative;
    .create-agency-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .create-agency-label {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: #2c3c55;
      }
    }
    .provide-details {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      color: #7183a5;
      padding-bottom: 16px;
    }
    .agency-modal-fields {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      input {
        box-sizing: border-box;
        padding: 16px;
        gap: 12px;
        width: 332px;
        height: 48px;
        border: 1px solid #a7b6d1;
        border-radius: 4px;
      }
    }
    .roles-permissions {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #4b6182;
      padding: 10px 0px 10px 0px;
    }
    .multi-choice {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .agency-multiple-qs {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #7183a5;
      }
    }
    .multi-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      color: #7183a5;
    }
    .add-agency-btn {
      // float: right;
      padding: 16px 40px;
      background: #4c8bf5;
      border-radius: 4px;
      width: 175px;
      margin-top: 10px;

      position: relative;
      right: -100%;
      transform: translate(-100%);
      &[disabled] {
        opacity: 0.5;
        cursor: wait; /* Set cursor to "loading" */
      }
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
      }
    }
  }
}
