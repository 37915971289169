.calendar {
  display: flex;
  flex-direction: column;
  &__back {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #2c3c55;
  }
  &__month-container {
    margin-top: 16px;
    display: flex;
    padding: 20px 24px;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 4px 40px rgba(67, 67, 67, 0.1);
    height: 64px;
    width: 100%;
    border: 1px solid #f3f2f1cc;
    section {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 22px;
      span {
        text-align: center;
        width: 100px;
        width: unset;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: #000;
      }
      svg {
        cursor: pointer;
      }
      svg:last-of-type {
        transform: rotate(180deg);
      }
    }
  }
  &__date-container {
    margin-top: 28.5px;
    display: flex;
    align-items: center;
    gap: 61px;
    flex-direction: row;
    margin-left: 66px;
    margin-right: 11px;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    section {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 103px;
      height: 92px;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      cursor: pointer;
      color: #a7b6d1;
      background: #e5f3ff;
      border-radius: 4px;
      &.active {
        color: white;
        background-color: #5ca8ff;
      }
    }
  }
  main {
    margin-top: 27px;
  }
}
