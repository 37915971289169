.employee_info_container {
  align-items: center;
  gap: 23px;
  .icon {
    width: 35px;
    height: 35px;
    background: #e5f3ff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .employee-content {
    gap: 5px;
    font-family: "Inter";
    font-style: normal;
    label {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #7183a5;
    }
    div {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #2c3c55;
      text-transform: capitalize;
    }
  }
}
