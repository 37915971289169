.create-new-shift-modal-2 {
  width: 727px;
  min-height: 301px;
  padding: 16px;

  padding: 16px;
  background: #ffffff;
  display: inline-block;

  position: relative;

  box-shadow: 2px 16px 40px 12px rgba(44, 49, 49, 0.16);
  border-radius: 8px;

  width: 564px;

  &__header {
    display: flex;
    justify-content: space-between;
  }
  .rate-input {
    outline: none;
    text-align: center;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    /* identical to box height, or 28px */

    /* Base/Black100 */

    color: #2c3c55;
  }
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */

    display: flex;
    align-items: center;

    /* Base/Black60 */

    color: #7183a5;

    margin-bottom: 21px;
  }

  .rate-container {
    width: 139px;
    height: 130px;

    background: #e5f3ff;
    border-radius: 4px;

    label {
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;

      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      color: #7183a5;

      span {
        margin-top: 12px;
      }
      .input-rate-wrapper {
        height: 68px;
        display: flex;
        align-items: center;
      }
    }

    input {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
      color: #4c8bf5;

      width: 100%;
      border: none;
      outline: none;
      background: inherit;
    }
  }
  input,
  .textarea {
    font: inherit;
  }

  .textarea {
    padding: 16px;

    flex-basis: 696px;
    height: 194px;
    border: 1px solid #a7b6d1;
    border-radius: 4px;
    textarea {
      border: none;
      outline: none;

      font-weight: 300;
      font-size: 16px;
      line-height: 150%;

      color: #7183a5;

      resize: none;

      width: 100%;
      height: 100%;
      &::placeholder {
        position: absolute;
        top: 0px;
      }
    }
  }
  .close-btn {
    background: #e5f3ff;
    opacity: 0.8;
    border-radius: 3px;
    display: inline-block;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 10.63px;
      height: 10.63px;
    }
  }
  .btn-next {
    padding: 12px 0;
    background: #4c8bf5;
    border: none;
    outline: none;
    border-radius: 4px;
    color: #ffffff;

    height: 48px;
    &[disabled] {
      opacity: 0.5;
      cursor: wait !important;
    }
  }
  .inputs-wrapper {
    display: flex;
    align-items: center;
    gap: 14px;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .rate-container {
      height: 130px;
    }
  }
}
