@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* @tailwind base; */
/* @tailwind components; */
@tailwind utilities;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 18px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #2c3c55;
  background: #f5f8fd;
}
