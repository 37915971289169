.user_request {
  display: flex;
  flex-direction: column;
  gap: 18px;
  &_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .request_section {
      display: flex;
      align-items: center;
      gap: 30px;
      .request_text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #7183a5;
      }
      .active {
        padding: 11px 14px;
        // height: 46px;
        background: #4c8bf5;
        border-radius: 4px;
        color: white;
      }
    }
    .new_shift {
      display: flex;
      align-items: center;
      gap: 10px;
      border: 2px solid #4c8bf5;
      border-radius: 4px;
      padding: 12px 16px;
      margin-block: 12px;
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #4c8bf5;
      }
    }
  }
  .card_section {
    // display: flex;
    // gap: 15px;
    gap: 15px;
    display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(372px, 440px));
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  }

  .pagination_wrapper{
    width: auto;
    margin:20px auto 0;
  }
}
