.give-employee-review-modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(44, 60, 85, 0.795);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .give-employee-review-modal {
    padding: 16px;
    background: #ffffff;
    display: inline-block;
    position: relative;
    box-shadow: 2px 16px 40px 12px rgba(44, 49, 49, 0.16);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //   gap:40px
  }
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;
    gap: 28px;
  }
  .error {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: red;
    margin-top: -8px;
    text-align: end;
    margin-bottom: 12px;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
  }
  .close-btn {
    cursor: pointer;
    background: #e5f3ff;
    opacity: 0.8;
    border-radius: 3px;
    display: inline-block;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .review-rows2 {
    // display: grid;
    // grid-template-columns: 1fr auto;
    // gap: 16px;
    // align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .MuiRating-root {
    label {
      // margin-left: 8px;
    }
  }
  .review-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    font-weight: 500;
    font-size: 14px;
  }
}
