.employee-list-container {
  .employee-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    .employee-label-container {
      display: flex;
      flex-direction: column;
      .employee-label {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 140%;
        color: #2c3c55;
      }
      .employee-subtitle {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        color: #7183a5;
      }
    }
    .create-employee-btn-container {
      display: flex;
      flex-direction: row;
      gap: 16px;
      .create-employee-btn {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px 32px;
        border: 2px solid #4c8bf5;
        border-radius: 4px;
        height: 48px;
        .create-employee-text {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          color: #4c8bf5;
          padding-left: 5px;
        }
      }
      .employee-dropdown {
        padding: 12px 16px;
        // width: 322px;

        padding: 20px 32px;

        height: 48px;
        border: 1px solid #a7b6d1;
        border-radius: 3px;
        align-items: center;
        .employee-dropdown-label-icon {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .label {
            font-family: "Inter";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 150%;
            color: #4b6182;
          }
        }
      }
    }
  }
  .employee_list_table_container {
    width: 100%;
    height: 560px;

    border-radius: 8px 8px 0px 0px;
    .details_table_cell {
      border: 1px solid #c5d4eb;
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #2c3c55;
      }
      .field-text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: #4b6182;
      }
    }
  }

  .pagination-container {
    margin-top: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .current-page-container {
      align-items: center;
      display: flex;
      gap: 13px;
      .current-page {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #2c3c55;
      }
      .page-count {
        padding: 10px 24px;
        gap: 10px;
        background: #ffffff;
        border: 1px solid #e5f3ff;
        border-radius: 8px;
        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          color: #2c3c55;
        }
      }
      .total-page {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #2c3c55;
      }
      .next-page {
        padding: 10px 18px;
        background: #8186e9;
        border-radius: 8px;
        cursor: pointer;
        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          color: #ffffff;
        }
      }
    }
  }
}

.action-icons {
  display: flex;
  gap: 5px;
}

.table-field-img-row {
  display: flex;
  gap: 12px;
}
