.change-image-container {
  margin-inline: auto;
  width: 50%;
  text-align: center;
  margin-top: 80px;

  .add-facility-btn {
    padding: 12px 54px;
    background: #4c8bf5;
    margin-top: 36px;
    border: none;
    outline: none;
    border-radius: 4px;
    position: relative;

    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
    margin-bottom: 30px;
  }
  .selected-images {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .selected-image-div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
  }
}
