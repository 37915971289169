.request-card {
  padding: 20px 16px;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(138, 138, 138, 0.08);
  border-radius: 4px;
  min-width: 100%;

  .request-container {
    display: flex;
    align-items: center;
    gap: 16px;
    .request-label {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      display: flex;
      color: #7183a5;
    }
  }
  .display-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    .request-count {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 44px;
      line-height: 120%;
      color: #2c3c55;
    }
    .request-view-btn {
      box-sizing: border-box;
      opacity: 0.8;
      border: 2px solid #4c8bf5;
      border-radius: 4px;
      padding: 10px 17px;
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        color: #4c8bf5;
        align-items: center;
      }
    }
  }
}
