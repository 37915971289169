
.carousel-text-title::selection {
  background-color: green;
  color: #fff;
}

.cursor-pointer {
  cursor: pointer;
}
.text-deco-none {
  text-decoration: none;
}
.flex-1 {
  flex: 1;
}
.visibility-hidden {
  visibility: hidden;
}

.loader {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  &.margin {
    margin-inline: auto;
  }
}
.loader:after {
  content: " ";
  display: block;
  width: 48px;
  height: 48px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #4c8bf5;
  border-color: #4c8bf5 transparent #4c8bf5 transparent;
  animation: loader 1.2s linear infinite;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
