.calendarItem {
  display: flex;
  align-items: center;
  gap: 22px;
  flex-direction: row;
  &__time {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #7183a5;
    white-space: nowrap;
    width: 65px;
  }
  &__timeline {
    height: 144px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 40px rgba(67, 67, 67, 0.1);
    display: flex;
    align-items: center;
    border-bottom: 2px solid #c5d4eb;
    padding-inline: 36px;
    gap: 8px;
    overflow-x: auto;
    .delete-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      border-radius: 6px;
      color: rgba(255, 0, 0, 0.8);
      transition: all 0.3s ease-in-out;
    }
    .delete-icon:hover {
      // background-color: rgba(255, 0, 0, 0.1);
      // color: red;
      color: blue;
    }
    div {
      min-width: 186px;
      min-width: 186px;
      padding: 18px 38px 18px 28px;
      background: #e5f3ff;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      gap: 2px;

      span {
        &.name {
          font-weight: 700;
          font-size: 14px;
          line-height: 150%;
          color: #2c3c55;
        }
        &.location {
          font-weight: 500;
          font-size: 12px;
          line-height: 160%;
          color: #7183a5;
        }
      }
    }
  }
}
