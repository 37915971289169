.add-employee {
  width: 728px;
  height: 243px;
  background: #ffffff;
  border-radius: 4px;
  padding: 16px 24px;
  .add-employee-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .add-employee-header {
      display: flex;
      flex-direction: column;
      gap: 2px;
      .add-employee-label {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: #2c3c55;
      }
      .add-employee-subtitle {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 160%;
        color: #7183a5;
      }
    }
  }
  .add-employee-inputs {
    display: flex;
    gap: 16px;
    margin: 24px 0px 32px 0px;
    .input-text-style {
      padding: 16px;
      gap: 12px;
      width: 332px;
      height: 48px;
      border: 1px solid #a7b6d1;
      border-radius: 4px;
    }
  }
  .add-btn {
    padding: 15px 45px;
    float: right;
    background: #4c8bf5;
    border-radius: 4px;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #ffffff;
    }
  }
}
