.edit-agency-modal-wrapper {
  display: flex;
  width: inherit;
  height: 100vh;
  align-items: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99;
  .edit-agency-modal {
    background: white;
    .multi-choice {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .agency-multiple-qs {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #7183a5;
    }
    .add-agency-btn {
      padding: 16px 40px;
      background: #4c8bf5;
      border-radius: 4px;
      width: fit-content;
      margin-top: 1rem;
      position: relative;
      right: -100%;
      transform: translate(-100%);
      color: white;
      &[disabled] {
        opacity: 0.5;
        cursor: wait !important; /* Set cursor to "loading" */
      }
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
      }
    }
  }
}
