h1.success {
  font-size: 48px;
  color: #4c8bf5;
  margin-inline: auto;
  width: 70%;

  font-family: "Inter", sans-serif;
  text-align: center;
}

img.success {
  width: 60%;
  margin-inline: auto;
}

@media only screen and (max-width: 800px) {
  h1.success {
    font-size: 24px;
    font-weight: 600;
    width: 80%;
  }
}
