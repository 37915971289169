.request {
  min-width: 372px;
  padding: 24px 24px 16px 24px;
  box-shadow: 0px 0px 25px rgba(138, 138, 138, 0.08);
  border-radius: 4px;
  gap: 10px;
  background: #ffffff;
  button {
    cursor: pointer;
  }
  &_container {
    display: flex;
    justify-content: space-between;
    .content {
      display: flex;
      cursor: pointer;
      gap: 10px;
      align-items: center;
      .rating_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        .person_name {
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;
          color: #2c3c55;
        }
        .Rating {
          font-size: 14px;
        }
      }
    }
    .duration {
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      color: #7183a5;
    }
  }

  &_timeline {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    margin-bottom: 20px;
    &_section {
      display: flex;
      gap: 6px;
      margin-top: 15px;
      align-items: center;
      .currency_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .rate {
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          color: #7183a5;
        }
        .money {
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          color: #2c3c55;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
  }

  .request_buttons_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;

    .view_details {
      padding: 8px 24px;
      background: #4c8bf5;
      border-radius: 4px;
      border: none;
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 160%;
        color: #ffffff;
      }
    }
    .approval_container {
      display: flex;
      align-items: center;
      float: right;
      gap: 8px;
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 24px;
        border-radius: 4px;
        border: none;
        // font-size: 13.33px !important;
      }
      .reject {
        color: #ffffff;
        background-color: #f3807a;
      }
      .accept {
        color: #ffffff;
        background-color: #53d258;
      }
      .cancel {
        color: #ffffff;
        background-color: #f3807a;
      }
      .give_review {
        color: #ffffff;
        background-color: green;
      }
    }
  }
}
