.d-flex {
  display: flex;
}

.float-right {
  float: right;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.w-100 {
  width: 100%;
}

.mt-50 {
  margin-top: 50%;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}
.text-deco-none {
  text-decoration: none;
}
