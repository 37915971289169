.profile_settings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 16px;
  height: 69px;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(138, 138, 138, 0.08);
  border-radius: 4px;
  &_container {
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #2c3c55;
    }
  }
}
