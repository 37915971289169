.profile {
  &_container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    // width: 179px;
    background: #ffffff;
    border: 1px solid #e5f3ff;
    box-shadow: 0px 0px 25px rgba(138, 138, 138, 0.08);
    border-radius: 4px;
    position: relative;
    .img {
      height: 200px;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 4px 4px 0 0;
    }
    svg {
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        filter: brightness(90%);
      }
    }
  }
  &_content {
    display: flex;
    flex-direction: column;
    padding: 0 16px 12px 16px;
    gap: 8px;
    div {
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      color: #2c3c55;
    }
    p {
      font-weight: 300;
      font-size: 12px;
      line-height: 160%;
      color: #7183a5;
    }
    button {
      outline: none;
      border: none;
      width: 100%;
      background: #4c8bf5;
      border-radius: 4px;
      height: 28px;
      font-family: "Inter", sans-serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      color: #ffffff;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      &:hover {
        filter: brightness(90%);
      }
    }
  }
}

.avatar-image {
  // height: 100%;
  width: 100%;
}
