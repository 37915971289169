.toggle_btn {
  padding: 8px;
  background: #e5f3ff;
  border-radius: 4px;
  gap: 16px;
  &_container {
    width: 159px;
    height: 40px;
    cursor: pointer;
    border: none;
    background: none;
    border-radius: 4px;
    margin: 2px;
    transition: all 0.3s ease-in-out;
    &_text {
      font-family: "Inter";
      transition: all 0.3s ease-in-out;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      display: flex;
      align-items: center;
      color: #a7b6d1;
    }
    &.active {
      background-color: white;
      box-shadow: 0px 0px 25px rgba(138, 138, 138, 0.08);
      svg {
        path {
          stroke: #4c8bf5;
        }
      }
      span {
        color: #4c8bf5;
      }
    }
  }
  &_icon {
    margin-right: 8px;
  }
  svg {
    transition: all 0.3s ease-in-out;
    path {
      transition: all 0.3s ease-in-out;
      stroke: #a7b6d1;
    }
  }
}
